// eslint-disable-next-line no-undef
if (typeof contactMethod !== "undefined" && contactMethod === "smtp") {
    Vue.createApp({
        data() {
            return {
                email: "",
                name: "",
                message: "",
                captcha: "",
                loading: false,
            };
        },
        methods: {
            send(event) {
                event.preventDefault();

                if (this.loading) {
                    return false;
                }

                this.loading = true;

                const { email, message, name, captcha } = this;

                return axios
                    .post("/api/v1/contact", { email, name, message, captcha })
                    .then(() => {
                        showToastr("Message correctement envoyé", true);
                    })
                    .catch((err) => {
                        showToastr(
                            err.response?.data?.message ||
                                "Impossible d'envoyer votre message",
                            false
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    }).mount("#contact");
}
