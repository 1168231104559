/* eslint-disable no-undef */
Vue.createApp({
    data() {
        return {
            // eslint-disable-next-line no-undef
            share: canPublish,
            q: "",
            year: "",
            country: "",
            format: "",
            loading: false,
            items: [],
            details: {},
            modalIsVisible: false,
            submitting: false,
            formats: [
                "Vinyl",
                "Acetate",
                "Flexi-disc",
                "Lathe Cut",
                "Mighty Tiny",
                "Shellac",
                "Sopic",
                "Pathé Disc",
                "Edison Disc",
                "Cylinder",
                "CD",
                "CDr",
                "CDV",
                "DVD",
                "DVDr",
                "HD DVD",
                "HD DVD-R",
                "Blu-ray",
                "Blu-ray-R",
                "Ultra HD Blu-ray",
                "SACD",
                "4-Track Cartridge",
                "8-Track Cartridge",
                "Cassette",
                "DC-International",
                "Elcaset",
                "PlayTape",
                "RCA Tape Cartridge",
                "DAT",
                "DCC",
                "Microcassette",
                "NT Cassette",
                "Pocket Rocker",
                "Revere Magnetic Stereo Tape Ca",
                "Tefifon",
                "Reel-To-Reel",
                "Sabamobil",
                "Betacam",
                "Betacam SP",
                "Betamax",
                "Cartrivision",
                "MiniDV",
                "Super VHS",
                "U-matic",
                "VHS",
                "Video 2000",
                "Video8",
                "Film Reel",
                "HitClips",
                "Laserdisc",
                "SelectaVision",
                "VHD",
                "Wire Recording",
                "Minidisc",
                "MVD",
                "UMD",
                "Floppy Disk",
                "File",
                "Memory Stick",
                "Hybrid",
                "All Media",
                "Box Set",
            ],
        };
    },
    created() {
        window.addEventListener("keydown", this.keyDown);
    },
    destroyed() {
        window.removeEventListener("keydown", this.keyDown);
    },
    methods: {
        search(event) {
            event.preventDefault();

            if (this.loading) {
                return false;
            }

            this.loading = true;
            let url = `/api/v1/search?q=${this.q}`;

            if (this.year) {
                url += `&year=${this.year}`;
            }
            if (this.country) {
                url += `&country=${this.country}`;
            }
            if (this.format) {
                url += `&format=${this.format}`;
            }

            return axios
                .get(url)
                .then((response) => {
                    const { results } = response.data;
                    const items = [];

                    for (let i = 0; i < results.length; i += 1) {
                        const {
                            id,
                            title,
                            thumb,
                            year,
                            country,
                            format,
                            genre,
                            style,
                            inCollection,
                        } = results[i];
                        items.push({
                            id,
                            title,
                            thumb,
                            year,
                            country,
                            format,
                            genre,
                            style,
                            inCollection,
                        });
                    }

                    this.items = items;
                })
                .catch((err) => {
                    showToastr(
                        err.response?.data?.message ||
                            "Aucun résultat trouvé :/"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        toggleModal() {
            this.modalIsVisible = !this.modalIsVisible;
        },
        loadDetails(discogsId) {
            axios
                .get(`/api/v1/search/${discogsId}`)
                .then((response) => {
                    const { data } = response;

                    this.details = data;
                    this.toggleModal();
                })
                .catch((err) => {
                    showToastr(
                        err.response?.data?.message ||
                            "Impossible de charger les détails de cet album"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        add() {
            if (this.submitting) {
                return true;
            }
            this.submitting = true;

            return axios
                .post(`/api/v1/${action}`, {
                    album: this.details,
                    share: this.share,
                })
                .then(() => {
                    window.location.href =
                        action === "albums"
                            ? "/ma-collection"
                            : "/ma-liste-de-souhaits";
                })
                .catch((err) => {
                    this.submitting = false;
                    showToastr(
                        err.response?.data?.message ||
                            "Impossible d'ajouter cet album pour le moment…"
                    );
                });
        },
        orderedItems(items) {
            return items.sort();
        },
        keyDown(event) {
            const keycode = event.code;

            if (this.modalIsVisible && keycode === "Escape") {
                event.preventDefault();
                this.modalIsVisible = false;
            }
        },
    },
}).mount("#ajouter-album");
