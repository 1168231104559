/* eslint-disable no-unused-vars */
const { protocol, host } = window.location;

let timeout = null;

/**
 * Fonction permettant d'afficher un message dans un toastr
 * @param {String} message
 */
function showToastr(message, success = false) {
    const x = document.getElementById("toastr");
    if (message) {
        x.getElementsByTagName("SPAN")[0].innerHTML = message;
    }

    if (timeout) {
        clearTimeout(timeout);
        x.classList.remove("show");
    }

    x.classList.remove("success");
    x.classList.remove("error");
    if (success) {
        x.classList.add("success");
    } else {
        x.classList.add("error");
    }

    x.classList.add("show");

    timeout = setTimeout(() => {
        x.classList.remove("show");
    }, 3000);
}

/**
 * Fonction permettant de masquer le toastr
 */
function hideToastr() {
    const x = document.getElementById("toastr");

    x.className = x.className.replace("show", "");
    x.getElementsByTagName("SPAN")[0].innerHTML = "";
}

/**
 * Ensemble d'actions effectuées au chargement de la page
 */
document.addEventListener("DOMContentLoaded", () => {
    const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0
    );
    if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach((el) => {
            el.addEventListener("click", () => {
                const { target } = el.dataset;
                const $target = document.getElementById(target);

                el.classList.toggle("is-active");
                $target.classList.toggle("is-active");
            });
        });
    }
});
