/**
 * Fonction permettant de récupérer le thème du système
 * @return {String}
 */
function getPreferredColorScheme() {
    if (window.matchMedia) {
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            return "dark";
        }
        return "light";
    }
    return "light";
}

/**
 * @param {String} scheme
 */
function setPictoOnMenu(scheme) {
    document.querySelectorAll(".icon-theme").forEach((item) => {
        item.classList.add("hidden");
    });
    document
        .querySelector(`.icon-theme.theme-${scheme}`)
        .classList.remove("hidden");
}

/**
 * Fonction permettant de sauvegarder dans le stockage local le choix du thème
 * @param {String} scheme
 */
function saveColorScheme(scheme) {
    localStorage.setItem("theme", scheme);
}

/**
 * Fonction permettant de changer le thème du site
 * @param {String} scheme
 */
function setColorScheme(scheme) {
    document.documentElement.setAttribute(
        "data-theme",
        scheme === "system" ? getPreferredColorScheme() : scheme
    );

    setPictoOnMenu(scheme);
}

/**
 * Fonction déclenchée lorsqu'un utilisateur clique sur un bouton dans le menu déroulant
 * @param {Object} e
 */
function changeTheme(e) {
    e.preventDefault();

    const scheme = this.dataset.value;

    saveColorScheme(scheme);
    setColorScheme(scheme);
}

// INFO: On place un event sur le bouton
const buttonsTheme = document.getElementsByClassName("theme");
// INFO: On récupère du local storage (ou des préférences navigateur) le thème actuel
const currentTheme = localStorage.getItem("theme") || getPreferredColorScheme();

/**
 * Event permettant de détecter les changements de thème du système
 */
if (window.matchMedia) {
    const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    colorSchemeQuery.addEventListener("change", () => {
        const selectedColorScheme = localStorage.getItem("theme") || "system";

        if (selectedColorScheme === "system") {
            const preferedColorScheme = getPreferredColorScheme();
            setColorScheme(preferedColorScheme);
        }
    });
}

// INFO: Au chargement de la page on détecte le thème à charger
setColorScheme(currentTheme);

// INFO: On place un event au click sur chacun des boutons du menu
for (let i = 0; i < buttonsTheme.length; i += 1) {
    buttonsTheme[i].addEventListener("click", changeTheme, false);
}
