/* eslint-disable no-undef */
Vue.createApp({
    data() {
        return {
            file: "",
            content: [],
            parsed: false,
            imported: 0,
            disabled: true,
            state: "default",
        };
    },
    created() {},
    destroyed() {},
    methods: {
        handleFileUpload(event) {
            const { files } = event.target;
            const [csv] = files;

            this.file = csv;

            this.file = csv;
            // this.parseFile();

            const reader = new FileReader();
            reader.onload = (content) => {
                this.content = [];
                this.state = "parse";
                const lines = content.target.result.split(/\r\n|\n/);
                for (let line = 1; line < lines.length - 1; line += 1) {
                    this.parseLine(lines[0], lines[line]);
                }

                this.state = "default";
                this.disabled = false;
            };

            reader.readAsText(csv);
        },
        parseLine(header, line) {
            const row = {};
            let currentHeaderIndex = 0;

            let separant = ",";
            let value = "";
            for (let i = 0; i < line.length; i += 1) {
                const char = line[i];

                if (char !== separant) {
                    if (char === '"') {
                        separant = '"';
                    } else {
                        value += char;
                    }
                } else if (char === '"') {
                    separant = ",";
                } else {
                    row[header.split(",")[currentHeaderIndex]] = value;
                    currentHeaderIndex += 1;
                    value = "";
                }
            }
            this.content.push(row);
        },
        async addOne(index) {
            const { Artist, Title, release_id } = this.content[index];

            try {
                const res = await axios.get(
                    `/api/v1/${action}?discogsId=${release_id}`
                );

                if (res.status === 204) {
                    await axios.post(`/api/v1/${action}`, {
                        discogsId: release_id,
                        share: false,
                    });
                }

                this.imported += 1;
                if (this.content.length > index + 1) {
                    await this.addOne(index + 1);
                }
            } catch (err) {
                showToastr(
                    `Impossible d'ajouter l'album ${Title} de ${Artist}`
                );

                return false;
            }

            return true;
        },
        async importCollection(event) {
            event.preventDefault();

            this.disabled = true;
            this.state = "submit";
            this.imported = 0;

            const imported = await this.addOne(0);

            this.disabled = false;
            this.state = imported ? "done" : "default";
        },
    },
}).mount("#importer");
